import React from "react";
import phoneIcon from '../../icons/phone-icon.svg';
import containerIcon from '../../icons/container-icon.svg';
import dataStoreIcon from '../../icons/datastore-icon.svg';
import cloudIcon from '../../icons/cloud-icon.svg';
import ciIcon from '../../icons/ci-icon.svg';

const Technologies = () => {
    return (
        <section className="flex flex-col justify-center px-4 py-8 lg:px-14 lg:py-16">
            <div className="flex flex-col justify-center lg:flex-row h-full items-center lg:items-start">
                {/* Text Section */}
                <div className="flex flex-col h-full justify-center items-center lg:items-start w-full lg:w-1/3 lg:pr-12 space-y-4 lg:space-y-8 text-center lg:text-left">
                    <div className="text-3xl md:text-4xl lg:text-5xl">
                        <div className="text-gray-300/75">
                            Which
                        </div>
                        <div className="text-gray-300/75">
                            technologies
                        </div>
                        <div className="text-gray-300/75">
                            you use
                        </div>
                        <div className="text-white font-bold italic">
                            MATTERS
                        </div>
                        <div className="text-gray-300/75">
                            a lot!
                        </div>
                    </div>
                </div>

                {/* Technologies Section */}
                <div className="flex flex-col pt-10 lg:pt-0 lg:w-2/3 space-y-10 lg:space-y-8">
                    <div
                        className="flex flex-row justify-center italic lg:justify-start h-max items-center space-x-5 text-white font-semibold text-xl lg:text-2xl">
                        <div>I am familiar with the following</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={phoneIcon} alt="mobile icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Cross platform mobile development and native Android</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={dataStoreIcon} alt="backend icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Backend development with Spring Boot</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={cloudIcon} alt="cloud icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Cloud Solutions with AWS, Google Cloud or Firebase</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={containerIcon} alt="container icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Containerisation with Docker and deployment in Kubernetes cluster</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={ciIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                        <div>Continuous Integration and Continuous Delivery</div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Technologies;