import './App.css';
import Content from "./portfolio/components/content/Content";
import NavBar from "./portfolio/components/navbar/NavBar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LegalNotice from "./portfolio/components/legal_notice/LegalNotice";
import ContentPantryMaster from "./pantrymaster/components/pm-content/ContentPantryMaster";

function App() {
  return (
      <div className="font-sans relative min-h-screen">
          <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-b from-darkGreen to-lightGreen"></div>
          <div className="relative z-10">
              <nav className="fixed top-0 left-0 w-full p-4">
                  <NavBar/>
              </nav>
              <main className="pt-16 lg:pt-20 w-full    ">
                  <BrowserRouter>
                      <Routes>
                          <Route path="/" element={<Content/>}/>
                          <Route path="/legalnotice" element={<LegalNotice/>}/>
                          <Route path="/pantrymaster" element={<ContentPantryMaster/>}/>
                      </Routes>
                  </BrowserRouter>
              </main>
          </div>
      </div>

  );
}

export default App;
