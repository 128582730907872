import aiIcon from "../../../pantrymaster/icons/ai-icon.svg";
import groupIcon from "../../../pantrymaster/icons/group-icon.svg";
import mealIcon from "../../../pantrymaster/icons/meal-icon.svg";
import notificationIcon from "../../../pantrymaster/icons/notification-icon.svg";
import shoppingIcon from "../../../pantrymaster/icons/shopping-icon.svg";
import React from "react";

const PantryMasterFeatures = () => {
    return (
        <section className="flex flex-col w-full justify-center px-4 py-8 lg:px-14 lg:py-16">
            <div className="flex flex-col lg:flex-row w-full h-full justify-center items-center">
                <div className="flex flex-col pt-10 lg:pt-0 lg:w-2/3 space-y-10 lg:space-y-8 justify-between">
                    <div className="text-3xl md:text-4xl lg:text-5xl text-center">
                        <div className="text-gray-300/75">
                            What are
                        </div>
                        <div className="text-gray-300/75">
                            the
                        </div>
                        <div className="text-white font-bold italic">
                            CORE
                        </div>
                        <div className="text-gray-300/75">
                            features
                        </div>
                        <div className="text-gray-300/75">
                            of Pantry Master?
                        </div>
                    </div>
                </div>

                <div className="flex flex-col pt-10 lg:pt-0 lg:w-2/3 space-y-10 lg:space-y-8">
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={groupIcon} alt="mobile icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Collaborative management of all your items</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={aiIcon} alt="backend icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Artificial Intelligence to support you where possible</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={shoppingIcon} alt="cloud icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>Digital, Group-based shopping list</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={mealIcon} alt="container icon" className="w-12 h-12 lg:w-16 lg:h-16"/>
                        <div>In-App meal planner and recipe generator</div>
                    </div>
                    <div
                        className="flex flex-row h-max items-center space-x-5 text-white font-semibold text-lg lg:text-xl">
                        <img src={notificationIcon} alt="container icon" className="w-12 h-12 lg:w-14 lg:h-14"/>
                        <div>Notifications with near-to expire items</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PantryMasterFeatures;