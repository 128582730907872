import React from "react";
import portrait from '../../icons/portrait-colored-svg.svg';
import { Typewriter } from 'react-simple-typewriter';

const Landing = () => {
    return (
        <section className="flex flex-col justify-start lg:justify-center lg:px-14 py-8 lg:py-10">
            <div className="flex flex-col lg:flex-row h-full justify-between items-center space-y-12 lg:space-y-0">
                <div className="flex flex-col justify-center items-center lg:items-start space-y-5 text-center lg:text-left">
                    <div className="font-bold text-white text-3xl md:text-4xl lg:text-5xl">
                        Hi! I'm Maurice Woike
                    </div>
                    <div>
                        <div className="text-white text-md lg:text-lg font-medium">
                            Founder of Pantry Master &<br/>Chief Technology Officer at Atmosphereum
                        </div>
                    </div>
                    <div className="pt-5">
                        <ul className="text-3xl md:text-4xl lg:text-5xl lg:space-y-3">
                            <li className="text-white font-bold italic">
                                <Typewriter
                                    words={['Innovative', 'Creative', 'Dynamic']}
                                    loop={false}
                                    cursor
                                    cursorStyle="_"
                                    typeSpeed={130}
                                    deleteSpeed={70}
                                    delaySpeed={1000}
                                />
                            </li>

                            <li className="text-gray-300/75">solutions,</li>
                            <li>
                                <div className="flex flex-col lg:flex-row lg:space-x-2">
                                    <div className="text-gray-300/75">
                                        tailored for
                                    </div>
                                    <div className="text-white font-bold italic">
                                        YOU
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col w-full lg:w-1/2 justify-center items-center text-center lg:text-left">
                    <div className="flex flex-row w-full justify-center items-center mb-4 lg:mb-0">
                        <img src={portrait} alt={"portrait"} className="w-1/2 h-full"/>
                    </div>
                    <div className="w-full text-orange-300 italic font-semibold text-lg text-center md:text-xl lg:text-xl">
                        "The only way to do great work is to love what you do." - Steve Jobs
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Landing;