import adItemOverview from '../../icons/ad-item-overview.svg';
import React from "react";

const PantryMasterItemOverview = () => {
    return (
        <section className="flex flex-col lg:justify-center items-center py-14">
            <div className="flex flex-col lg:flex-row w-full justify-between items-center space-y-5 lg:space-x-24 lg:px-20 text-center lg:text-left">
                <div className="flex flex-row w-full justify-center lg:justify-start">
                    <img src={adItemOverview} alt="AI Functions"/>
                </div>
                <div className="flex flex-col text-white font-bold space-y-12 text-2xl px-10 lg:px-0">
                    <div className="flex flex-row w-full justify-start text-start">
                        5. See expire date directly
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        6. Specify name, description, price and storage location of an item
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        7. Get healthiness information just by a button click
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        8. Receive notification when an item is below your minimum quantity
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PantryMasterItemOverview;