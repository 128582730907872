import React from 'react';

const Timeline = () => {
    const events = [
        { description: "Until A-Level I built hobby projects with the Raspberry Pi. For example, small smart home or alarm systems.", date: "until 2023" },
        { description: "Became a Working Student in Computer Science and developed enterprise and scalable apps.", date: "since 2023" },
        { description: "Became a freelance software developer.", date: "May 2024" },
        { description: "Founded Pantry Master, an AI powered mobile app.", date: "May 2024" },
        { description: "Chief Technology Officer (CTO) and active developer on mobile side at Atmosphereum.", date: "Jul 2024" }
    ];

    return (
        <div className="flex flex-col md:flex-row items-center lg:items-start justify-center py-10 px-4 lg:px-10 lg:py-28">
            {events.map((event, index) => (
                <div key={index} className="flex flex-col items-center relative mb-12 md:mb-0 md:mx-6">
                    <div className="text-white font-semibold text-base md:text-xl mb-2">
                        {event.date}
                    </div>

                    <div
                        className="w-8 h-8 md:w-10 md:h-10 rounded-full bg-orange-400 flex items-center justify-center text-white text-base md:text-lg font-semibold z-10">
                        {index + 1}
                    </div>

                    <div className="mt-4 text-center max-w-xs">
                        <div className="text-white text-sm md:text-lg font-semibold">
                            {event.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>

    );
};

export default Timeline;

