import React from "react";
import adAiFunction from '../../icons/ad-ai-functions.svg';

const PantryMasterAiFunctions = () => {
    return (
        <section className="flex flex-col lg:justify-center items-center py-14">
            <div className="flex flex-col lg:flex-row w-full justify-between items-center space-y-5 lg:space-x-24 lg:px-20 text-center lg:text-left">
                <div className="flex flex-col justify-start items-start w-full text-white font-bold px-10 lg:px-0 space-y-12 text-2xl">
                    <div className="flex flex-row w-full justify-start text-start">
                        1. Generate recipes based on the items which are near to expire to reduce food waste
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        2. In-App chat assistant Pantry AI to assist you in every moment and with any question
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        3. Take a picture of any food items and generate recipes out of it
                    </div>
                    <div className="flex flex-row w-full justify-start text-start">
                        4. Create a meal plan just by your preferences - easy and comfortable
                    </div>
                </div>
                <div className="flex flex-row justify-start lg:justify-end">
                    <img src={adAiFunction} alt="AI Functions"/>
                </div>
            </div>
        </section>
    )
}

export default PantryMasterAiFunctions;