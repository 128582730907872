import React from "react";
import PantryMasterLanding from "../pm-landing/PantryMasterLanding";
import PantryMasterFeatures from "../pm-features/PantryMasterFeatures";
import PantryMasterAiFunctions from "../pm-aifunctions/PantryMasterAiFunctions";
import PantryMasterItemOverview from "../pm-item-overview/PantryMasterItemOverview";

const ContentPantryMaster = () => {
    return (
        <div className="flex flex-col min-h-full">
            <PantryMasterLanding/>
            <PantryMasterFeatures/>
            <PantryMasterAiFunctions/>
            <PantryMasterItemOverview/>
            <footer className="flex w-full justify-center items-center pb-6">
                <a href="/legalnotice" className="text-white font-semibold">Impressum</a>
            </footer>
        </div>
    )
}

export default ContentPantryMaster;