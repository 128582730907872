import React from "react";

const Contact = () => {
    return (
        <section className="flex flex-col justify-center px-4 py-8 lg:px-14 lg:py-16">
            <div className="flex flex-col lg:flex-row h-full lg:justify-between space-y-8 lg:space-y-0">
                {/* Text Section */}
                <div
                    className="flex flex-col justify-center items-center lg:items-start text-center lg:text-left space-y-5 lg:w-1/2">
                    <ul className="text-4xl md:text-5xl lg:text-6xl space-y-3">
                        <li className="text-gray-300/75">Let's get in</li>
                        <li>
                            <div className="flex flex-row justify-center lg:justify-start space-x-2">
                                <div className="text-white font-bold italic">TOUCH</div>
                            </div>
                        </li>
                    </ul>
                </div>

                {/* Contact Info Section */}
                <div className="flex flex-col text-white text-base md:text-lg lg:text-2xl space-y-4 text-center lg:text-end lg:w-1/2">
                    <div>
                        <div className="font-semibold">Maurice Woike</div>
                        <div className="text-sm">Freelance Software Developer</div>
                    </div>
                    <div>tel: <a href="tel:+4915256614697">+49 1525 6614697</a></div>
                    <div>mail: <a href="mailto:maurice.woike@gmail.com">maurice.woike@gmail.com</a></div>
                    <div>Based near Brunswick, Germany</div>
                    <div className="font-bold">Or checkout <a
                        href="https://www.linkedin.com/in/maurice-woike/">LinkedIn</a> to send me a direct message!
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Contact;