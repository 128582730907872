import adStarter from "../../../pantrymaster/icons/ad-starter.svg";
import React from "react";

const PantryMasterLanding = () => {
    return (
        <section className="flex flex-col lg:justify-center items-center py-14">
            <div className="flex flex-col justify-center items-center space-y-5 md:px-10 text-center lg:text-left">
                <div
                    className="flex flex-row font-bold w-full text-center justify-center text-white text-3xl md:text-4xl lg:text-5xl">
                    Pantry Master
                </div>
                <div className="flex flex-row flex-wrap w-full text-center justify-center text-white px-5 text-3xl md:text-4xl lg:text-5xl space-x-5">
                    <div className="text-gray-300/75">
                        Your
                    </div>
                    <div className="text-white font-bold italic">
                        digital and AI powered
                    </div>
                    <div className="text-gray-300/75">
                        household management tool
                    </div>
                </div>
                <div className="flex flex-row text-white text-xl italic">
                    <div>
                        Get it on&nbsp;
                    </div>
                    <a className="font-bold" href="https://play.google.com/store/apps/details?id=de.pantrymaster">
                        Google Play
                    </a>
                    <div>
                        &nbsp;or&nbsp;
                    </div>
                    <a className="font-bold" href="https://apps.apple.com/us/app/pantry-master/id6502577884?platform=iphone">
                        App Store
                    </a>
                </div>
                <div className="flex flex-col lg:flex-row w-full h-min justify-center items-center pt-20 space-y-8">
                    <div className="flex flex-col w-10/12 lg:w-1/3 justify-center text-white font-bold text-2xl space-y-8 lg:space-y-28">
                        <div className="flex flex-row">
                            Unlimited group members for each group
                        </div>
                        <div className="flex flex-row">
                            4+ possibilities to add new items
                        </div>
                    </div>
                    <div className="flex flex-col w-full items-center">
                        <img src={adStarter} alt="Pantry Master Start" className=""/>
                    </div>
                    <div className="flex flex-col w-10/12 lg:w-1/3 justify-center text-white font-bold text-2xl space-y-8 lg:space-y-28">
                        <div className="flex flex-row">
                            8+ AI powered functions currently
                        </div>
                        <div className="flex flex-row">
                            Reduce food waste just by one click
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PantryMasterLanding;