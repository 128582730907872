import React from "react";
import Timeline from "../timeline/Timeline";

const Experience = () => {
    return (
        <section className="flex flex-col px-4 py-8 lg:px-14 lg:py-16">
            <div className="text-3xl md:text-4xl lg:text-5xl text-gray-300/75 pl-4 lg:pl-20 pt-10">
                And where does my
            </div>
            <div className="text-3xl md:text-4xl lg:text-5xl pl-4 lg:pl-20 space-x-2">
                <div className="text-white font-bold inline">
                    EXPERIENCE
                </div>
                <div className="text-gray-300/75 inline">
                    come from?
                </div>
            </div>
            <div className="pt-8 lg:pt-16">
                <Timeline/>
            </div>
        </section>

    )
}

export default Experience;